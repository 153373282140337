/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages 
                setTimeout(function() {
                    $('body, html').scrollTop(0);
                    $('.load-overlay').velocity('fadeOut');
                }, 100);

                $('.liquid').imgLiquid();

                $('.enter_license').click(function(){
                    var course = $(this).attr('data-course');

                    $('.course_activation_modal[data-course="'+ course +'"]').velocity('fadeIn');
                });

                $('.course_activation_modal .close').click(function(){
                    $('.course_activation_modal').velocity('fadeOut');
                });

                $(document).ready(function() {
                    $('.sub-items').css({display: 'none'});
                });

                $(function() {
                    $(document).ready(function () {

                        $('.nav-title').click(function (event) {

                            event.stopPropagation();

                            $(this).find(".sub-items").toggle();


                        });

                        $(document).click(function () {

                            $('.sub-items').hide();
                        });

                    });
                });


                $(document).click(function(e)
                {
                    var container = $('.chapters ul');

                    // if the target of the click isn't the container nor a descendant of the container
                    if (!container.is(e.target) && container.has(e.target).length === 0)
                    {
                        $('.chapters').addClass('closed').fadeOut();
                    }
                });

                $('.chapters .close').click(function(){
                    $('.chapters').addClass('closed').fadeOut();
                });

                $('.bottom-bar .more').click(function(){
                    setTimeout(function(){
                        $('.chapters').fadeIn().removeClass('closed');
                    }, 100);
                });

                $('.course_nav .title').click(function(){
                    var id = $(this).attr('data-id');
                    $('.chapter').hide();
                    $('.item').hide();
  
                    $('.chapter[data-id="'+ id +'"]').show();
                    $('.item[data-parent="'+ id +'"]').show();
                });

                $('.item').each(function(){
                    var par = $(this).attr('data-parent');
                    $('.item[data-id="'+ par +'"] span').show();
                });

                $('.item span').click(function(e){
                    e.preventDefault();
                    var id = $(this).parent().attr('data-id');
                    $('.item[data-parent="'+ id +'"]').show();
                });

                var activeItem = 0;
                activeItem = $('.item.active').attr('data-parent');
        
                if(activeItem > 0) {
                    var wrap = $('.item.active').parent();
                    var parent = wrap.attr('data-id');


                    $('.item[data-parent="'+ parent + '"]').show();
                    $('.item[data-parent="'+ activeItem + '"]').show();
                    wrap.show();
                }

                // homepage search
                var client = algoliasearch('OSSIR6A003', '85bee5571093d93bd0dba8d988a83a7c');
                var index = client.initIndex('wp_posts_course');
               
                //initialize autocomplete on search input (ID selector must match)
                autocomplete('#search-input',
                { hint: true, debug: true }, {
                    source: autocomplete.sources.hits(index, {hitsPerPage: 3, filters: 'wpml.locale:"' + global.current_locale + '"'}),
                    templates: {
                        suggestion: function(suggestion) {
                          return '<a href="'+ suggestion.permalink +'"><div class="title">' +
                          suggestion._highlightResult.post_title.value + '</div></a>';
                        }
                    }
                });

                
                $('.course-single .more-btn').click(function(){
                    console.log('123');
                    $('.side_menu-grid').toggle();
                });
    
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
            +function ($) { "use strict";

                /**
                 * The zoom service
                 */
                function ZoomService () {
                    this._activeZoom            =
                        this._initialScrollPosition =
                            this._initialTouchPosition  =
                                this._touchMoveListener     = null

                    this._$document = $(document)
                    this._$window   = $(window)
                    this._$body     = $(document.body)

                    this._boundClick = $.proxy(this._clickHandler, this)
                }

                ZoomService.prototype.listen = function () {
                    this._$body.on('click', '[data-action="zoom"]', $.proxy(this._zoom, this))
                }

                ZoomService.prototype._zoom = function (e) {
                    var target = e.target;

                    if (!target || target.tagName != 'IMG') return;

                    if (this._$body.hasClass('zoom-overlay-open')) return;

                    if (e.metaKey || e.ctrlKey) {
                        return window.open((e.target.getAttribute('data-original') || e.target.src), '_blank')
                    }

                    if (target.width >= ($(window).width() - Zoom.OFFSET)) return;

                    this._activeZoomClose(true);

                    this._activeZoom = new Zoom(target);
                    this._activeZoom.zoomImage();

                    // todo(fat): probably worth throttling this
                    this._$window.on('scroll.zoom', $.proxy(this._scrollHandler, this));

                    this._$document.on('keyup.zoom', $.proxy(this._keyHandler, this));
                    this._$document.on('touchstart.zoom', $.proxy(this._touchStart, this));

                    // we use a capturing phase here to prevent unintended js events
                    // sadly no useCapture in jquery api (http://bugs.jquery.com/ticket/14953)
                    if (document.addEventListener) {
                        document.addEventListener('click', this._boundClick, true)
                    } else {
                        document.attachEvent('onclick', this._boundClick, true)
                    }

                    if ('bubbles' in e) {
                        if (e.bubbles) e.stopPropagation()
                    } else {
                        // Internet Explorer before version 9
                        e.cancelBubble = true
                    }
                };

                ZoomService.prototype._activeZoomClose = function (forceDispose) {
                    if (!this._activeZoom) return;

                    if (forceDispose) {
                        this._activeZoom.dispose()
                    } else {
                        this._activeZoom.close()
                    }

                    this._$window.off('.zoom');
                    this._$document.off('.zoom');

                    document.removeEventListener('click', this._boundClick, true);

                    this._activeZoom = null
                };

                ZoomService.prototype._scrollHandler = function (e) {
                    if (this._initialScrollPosition === null) this._initialScrollPosition = $(window).scrollTop()
                    var deltaY = this._initialScrollPosition - $(window).scrollTop();
                    if (Math.abs(deltaY) >= 40) this._activeZoomClose()
                };

                ZoomService.prototype._keyHandler = function (e) {
                    if (e.keyCode == 27) this._activeZoomClose()
                };

                ZoomService.prototype._clickHandler = function (e) {
                    if (e.preventDefault) e.preventDefault();
                    else event.returnValue = false;

                    if ('bubbles' in e) {
                        if (e.bubbles) e.stopPropagation()
                    } else {
                        // Internet Explorer before version 9
                        e.cancelBubble = true
                    }

                    this._activeZoomClose()
                };

                ZoomService.prototype._touchStart = function (e) {
                    this._initialTouchPosition = e.touches[0].pageY;
                    $(e.target).on('touchmove.zoom', $.proxy(this._touchMove, this))
                };

                ZoomService.prototype._touchMove = function (e) {
                    if (Math.abs(e.touches[0].pageY - this._initialTouchPosition) > 10) {
                        this._activeZoomClose();
                        $(e.target).off('touchmove.zoom')
                    }
                };


                /**
                 * The zoom object
                 */
                function Zoom (img) {
                    this._fullHeight      =
                        this._fullWidth       =
                            this._overlay         =
                                this._targetImageWrap = null;

                    this._targetImage = img;

                    this._$body = $(document.body)
                }

                Zoom.OFFSET = 80;
                Zoom._MAX_WIDTH = 2560;
                Zoom._MAX_HEIGHT = 4096;

                Zoom.prototype.zoomImage = function () {
                    var img = document.createElement('img');
                    img.onload = $.proxy(function () {
                        this._fullHeight = Number(img.height);
                        this._fullWidth = Number(img.width);
                        this._zoomOriginal()
                    }, this);
                    img.src = this._targetImage.src
                };

                Zoom.prototype._zoomOriginal = function () {
                    this._targetImageWrap           = document.createElement('div');
                    this._targetImageWrap.className = 'zoom-img-wrap';

                    this._targetImage.parentNode.insertBefore(this._targetImageWrap, this._targetImage)
                    this._targetImageWrap.appendChild(this._targetImage);

                    $(this._targetImage)
                        .addClass('zoom-img')
                        .attr('data-action', 'zoom-out');

                    this._overlay           = document.createElement('div');
                    this._overlay.className = 'zoom-overlay';

                    document.body.appendChild(this._overlay);

                    this._calculateZoom();
                    this._triggerAnimation()
                };

                Zoom.prototype._calculateZoom = function () {
                    this._targetImage.offsetWidth // repaint before animating

                    var originalFullImageWidth  = this._fullWidth;
                    var originalFullImageHeight = this._fullHeight;

                    var scrollTop = $(window).scrollTop();

                    var maxScaleFactor = originalFullImageWidth / this._targetImage.width;

                    var viewportHeight = ($(window).height() - Zoom.OFFSET);
                    var viewportWidth  = ($(window).width() - Zoom.OFFSET);

                    var imageAspectRatio    = originalFullImageWidth / originalFullImageHeight;
                    var viewportAspectRatio = viewportWidth / viewportHeight;

                    if (originalFullImageWidth < viewportWidth && originalFullImageHeight < viewportHeight) {
                        this._imgScaleFactor = maxScaleFactor

                    } else if (imageAspectRatio < viewportAspectRatio) {
                        this._imgScaleFactor = (viewportHeight / originalFullImageHeight) * maxScaleFactor

                    } else {
                        this._imgScaleFactor = (viewportWidth / originalFullImageWidth) * maxScaleFactor
                    }
                }

                Zoom.prototype._triggerAnimation = function () {
                    this._targetImage.offsetWidth // repaint before animating

                    var imageOffset = $(this._targetImage).offset();
                    var scrollTop   = $(window).scrollTop();

                    var viewportY = scrollTop + ($(window).height() / 2);
                    var viewportX = ($(window).width() / 2);

                    var imageCenterY = imageOffset.top + (this._targetImage.height / 2);
                    var imageCenterX = imageOffset.left + (this._targetImage.width / 2);

                    this._translateY = viewportY - imageCenterY;
                    this._translateX = viewportX - imageCenterX;

                    var targetTransform = 'scale(' + this._imgScaleFactor + ')';
                    var imageWrapTransform = 'translate(' + this._translateX + 'px, ' + this._translateY + 'px)'

                    if ($.support.transition) {
                        imageWrapTransform += ' translateZ(0)'
                    }

                    $(this._targetImage)
                        .css({
                            '-webkit-transform': targetTransform,
                            '-ms-transform': targetTransform,
                            'transform': targetTransform
                        });

                    $(this._targetImageWrap)
                        .css({
                            '-webkit-transform': imageWrapTransform,
                            '-ms-transform': imageWrapTransform,
                            'transform': imageWrapTransform
                        });

                    this._$body.addClass('zoom-overlay-open')
                };

                Zoom.prototype.close = function () {
                    this._$body
                        .removeClass('zoom-overlay-open')
                        .addClass('zoom-overlay-transitioning');

                    // we use setStyle here so that the correct vender prefix for transform is used
                    $(this._targetImage)
                        .css({
                            '-webkit-transform': '',
                            '-ms-transform': '',
                            'transform': ''
                        });

                    $(this._targetImageWrap)
                        .css({
                            '-webkit-transform': '',
                            '-ms-transform': '',
                            'transform': ''
                        });

                    if (!$.support.transition) {
                        return this.dispose()
                    }

                    $(this._targetImage)
                        .one($.support.transition.end, $.proxy(this.dispose, this))
                        .emulateTransitionEnd(300)
                };

                Zoom.prototype.dispose = function () {
                    if (this._targetImageWrap && this._targetImageWrap.parentNode) {
                        $(this._targetImage)
                            .removeClass('zoom-img')
                            .attr('data-action', 'zoom');

                        this._targetImageWrap.parentNode.replaceChild(this._targetImage, this._targetImageWrap)
                        this._overlay.parentNode.removeChild(this._overlay);

                        this._$body.removeClass('zoom-overlay-transitioning')
                    }
                };

                // wait for dom ready (incase script included before body)
                $(function () {
                    new ZoomService().listen()
                })

            }(jQuery)


        }, 
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
